import React from "react";
import styles from "./Home.module.css";
import Timer from "./Timer";

function Home() {
  return (
    <div className={styles.App}>
      {/* <div className={styles.container}> */}
      <div className={styles.backgroundImageOne}>
        <div className={styles.upperOverlay}>
          <div className={styles.bodyContainer}>
            <div className={styles.txt}>
              While $KINGDOM  is available on Uniswap, do not expect any return
              on your trades. Read full disclaimer{" "}
              <a href="https://docs.kingdomgame.live/trading-disclaimer">
                {" "}
                here.{" "}
              </a>
            </div>
            <div className={styles.headerContainer}>
              <div className={styles.header}>
                <a href="/">
                  <img
                    className={styles.headerLogo}
                    src="./kingdom_logo.png"
                    alt=""
                  />
                </a>
                <div className={styles.nav}>
                  <a href="https://x.com/kingdomgame_erc">
                    <img src="./X.png" alt="" className={styles.Xbutton} />
                  </a>
                  <a href="https://t.me/Kingdom_erc20">
                    <img
                      src="./telegramX.png"
                      alt=""
                      className={styles.Xbutton}
                    />
                  </a>

                  <a href="https://www.twitch.tv/kingdomgame_erc">
                    <img src="./twitch.png" alt="" className={styles.Xbutton} />
                  </a>
                  <a href="https://docs.kingdomgame.live/">
                    <img
                      // class="headerButton"
                      className={styles.button}
                      src="./documentationX.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          {/* 
          <div class="bgImage">
            <img src="./bg.png" alt="" />
          </div> */}
          {/* <div className={styles.videoPlayer}>
            <iframe
              title="ID"
              src="https://player.castr.com/live_c84f6aa06aa511eebe76bbb652628f31/realtime"
              width="100%"
              frameBorder="0"
              scrolling="no"
              allow="autoplay"
              allowFullScreen
              webkitAllowFullScreen
              mozAllowFullScreen
              oAllowFullScreen
              msAllowFullScreen
            ></iframe>

           
          </div> */}
          {/* <div className={styles.kingdom}>What is Kingdom?</div> */}
          {/* <div className={styles.card}>
            <div className={styles.overlay}>
              <img src="./overlay.png" alt="" />
              <div className={styles.text}>
                <div className={styles.ETH}>
                  Kingdom is an ETH-based ERC-20 game.{" "}
                </div>
                Simplicity is at the heart of Kingdom. Get tokens, deposit them
                into the game, and engage in live gameplay alongside other
                players. The game is streamed live 24/7 on Twitch, X, and
                Telegram.
              </div>
            </div>
            <div className={styles.pic}>
              <img src="./gameImage1.png" alt="" />
            </div>
          </div> */}
          {/* <div className={styles.cardTwo}>
            <div className={styles.picc}>
              <img src="./gameImage2.png" alt="" />
            </div>
            <div className={styles.overlayy}>
              <div className={styles.textt}>
                <div className={styles.ETHH}>
                  Multiple levels, diverse mechanics, and challenges.
                </div>
                Your objective is to beat your fellow players within the
                allotted time, farm tokens, and become the King!
              </div>
              <img src="./overlay2.png" alt="" />
            </div>
          </div> */}

          <div className={styles.ETHH}>
            Due to a lack of funds, project is now closed, the in-game tokens
            have been distributed.
          </div>
        </div>
      </div>
      <div className={styles.backgroundImageTwo}>
        <div className={styles.backgroundOverlay}>
          {/* <div className={styles.bodyContainerTwo}>
            <div className={styles.cardThree}>
              <div className={styles.overlayyy}>
                <img src="./overlay3.png" alt="" />
                <div className={styles.texttt}>
                  <div className={styles.ETH}>
                    Unique reward and burn distribution mechanism
                  </div>
                  The game distributes the rewards in the way that 2% of all
                  game tokens get burned! So more players can play and
                  participate, and more the burn can happen.
                </div>
              </div>
              <div className={styles.pic}>
                <img src="./gameImage3.png" alt="" />
              </div>
            </div>
            <div className={styles.tutorialVideo}>
              <div className={styles.tutorialHeading}>Tutorial Video</div>
              <div className={styles.videoPlayerTwo}>
                <div className={styles.secondVideo}>
                  <video
                    src="https://dl.dropbox.com/scl/fi/yi7hw0uxue763f4pxwpv1/7063684346690541685.mp4?rlkey=jd3k3al3r2q3h5iitpmq31i5k&dl=0"
                    loop
                    controls
                    playsInline
                  />
                </div>
          
              </div>
            </div>

            <div className={styles.needInfo}>Need more information?</div>
            <div className={styles.document}>
              <a href="https://docs.kingdomgame.live/">
                <img src="./DocumentationCh.png" alt="" />
              </a>
            </div>
          </div> */}
          <div className={styles.footer}>
            <div className={styles.footerContainer}>
              <div className={styles.rightContainer}>
                {/* <div className={styles.buttonContainer}>
                  <a href="https://medium.com/@kingdomgame">
                    <img src="./medium.png" alt="" />
                  </a>
                  <a href="https://t.me/Kingdom_erc20">
                    <img src="./telegram.png" alt="" />
                  </a>
                  <a href="https://x.com/kingdomgame_erc">
                    <img src="./ButtonX.png" alt="" />
                  </a>
                  <a href="https://dune.com/whale_hunter/kingdom-game">
                    <img src="./analytics.png" alt="" />
                  </a>
                  <a href="https://dexscreener.com/ethereum/0x7ac46647b0b6850f445aaded0b2694c927cb0539">
                    <img src="./dexscreener.png" alt="" />
                  </a>
                  <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x7ac46647b0b6850f445aaded0b2694c927cb0539">
                    <img src="./dextools.png" alt="" />
                  </a>
                </div> */}

                <div className={styles.linkContainer}>
                  <div className={styles.footerLogo}>
                    <img src="./kingdom_logo.png" alt="" />
                  </div>
                  <div className={styles.subLinkCaontainer}>
                    <a href="https://docs.kingdomgame.live/">Documentation</a>
                    <a href="/legal">Legal</a>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.contractAddress}>
              Contract Address: 0x6cf9788CDa282aeb729477F6BDA9c4dB082B5746
            </div>
            <div className={styles.disclaimer}>
              Kingdom game is not available to people living in the United
              States or any other country that is subject to an embargo imposed
              by the U.S.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
