// import React from "react";
// import styles from "./Legal.module.css";

// export default function Legal() {
//   return (
//     <div class="container">
//       <div class='body'>
//         <div class="headerContainer">
//           <div class="header">
//             <a href="/">
//               <img class="headerLogo" src="./kingdom_logo.png" alt="" />
//             </a>
//             <a href="https://docs.kingdomgame.live/how-to-play">
//               <img
//                 class="headerButton"
//                 //   class="button"
//                 src="./button.png"
//                 alt=""
//               />
//             </a>
//           </div>
//           <div></div>
//         </div>
//         <h1 class="title">LEGAL</h1>
//         <h2 class="subHeading">LEGAL DISCLAIMER</h2>
//         <p>
//           <strong>Kingdom Game</strong> (“Game”) is intended for individuals who
//           are at least 18 years old, unless otherwise stated by local law. By
//           using the Game, you represent and warrant that you meet the age
//           requirements. If you are under the specified age, please refrain from
//           playing the Game.
//         </p>

//         <p>
//           Game was developed and designed primarily for entertainment and
//           amusement only. The Game is played using tokens.. The rewards are
//           limited to the high scores (/leaderboard) during the day and the
//           tokens collected within the game. To win the Game you require skill,
//           hand-eye coordination, and strategy to predict the outcome of the
//           Game. You can improve your performance through practice and
//           experience.
//         </p>

//         <p>
//           <strong>Kingdom Game</strong> will be available only in Telegram and
//           only for token holders. We reserve the right, in our sole discretion,
//           to remove, edit, or modify any features without notice to you.
//         </p>

//         <p>
//           Project native token <strong>$KINGDOM </strong>(“Token”) is available
//           for purchase on Uniswap. Under no circumstances does the
//           <strong>Kingdom Game</strong> team provide you with recommendations to
//           buy the Token, sell it, or hold it.
//         </p>

//         <p>
//           When purchasing the Token, you agree to the conditions that you are
//           not purchasing a security or investment, as well as you agreeing to
//           relieve the Team of any damage and responsibility for any losses or
//           tax liabilities you may incur. You also agree with the fact that when
//           offering the Token, the Team has no obligation to provide support or
//           services regarding the Token.
//         </p>

//         <p>
//           You should not have any expectations of the Token and the Team. Make
//           sure you comply with your local laws and regulations before making any
//           purchase of the Token. We do not bear any responsibility for any
//           losses or damages of any kind incurred as a result of using this
//           information or services. Please be aware of the risks related to
//           trading on a financial market connected with the feasible loss of
//           money. In this regard, we kindly recommend you not to trade with money
//           you are not ready to lose. In any case, you take the full
//           responsibility for your investments and trading decisions.
//         </p>

//         <h2>Prohibition of Bot Usage :-</h2>
//         <ul>
//           <li>
//             You are strictly prohibited from using any kind of automated trading
//             systems, bots including but not limited to sniper bots or other
//             software or technology that can manipulate the price, exploit
//             vulnerabilities, or unfairly benefit from the Token.
//           </li>
//           <br />
//           <li>
//             You shall not engage in any activity that disrupts or compromises
//             the integrity of the Token, the project ecosystem, or the platform
//             on which the Token is hosted.
//           </li>
//         </ul>

//         <p>
//           The project reserves the right to investigate any suspicious or
//           fraudulent activities related to the Token and take appropriate action
//           based on its findings.
//         </p>

//         <h2>Price Manipulation :-</h2>
//         <ul>
//           <li>
//             You shall not engage in any activity that artificially manipulates
//             the price or market conditions of the Token.
//           </li>
//           <br />

//           <li>
//             You shall not engage in pump-and-dump schemes, wash trading,
//             front-running, sniping, or any other manipulative practices that can
//             create a false or misleading market for the project's token.
//           </li>
//         </ul>

//         <p>
//           The Game is provided "as is" and "as available," without warranties of
//           any kind, whether express or implied. We do not warrant that the Game
//           will be error-free, uninterrupted, or free from viruses or other
//           harmful components.
//         </p>

//         <p>
//           To the fullest extent permitted by applicable law, the Kingdom Game
//           project shall not be liable for any indirect, incidental, special,
//           consequential, or punitive damages, or any loss of profits or
//           revenues, whether incurred directly or indirectly, or any loss of
//           data, use, goodwill, or other intangible losses resulting from your
//           use or inability to use the Game.
//         </p>

//         <p>
//           You agree to indemnify, defend, and hold harmless the Project, its
//           officers, directors, employees, and agents from and against any
//           claims, liabilities, damages, losses, and expenses arising out of your
//           use of the Game.
//         </p>

//         <h3>
//           IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, AGENTS, MANAGERS,
//           MEMBERS, PRINCIPALS, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS
//           (“RELATED PARTIES”), BE LIABLE UNDER CONTRACT, STRICT LIABILITY,
//           NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE
//           PROJECT FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF
//           SUBSTITUTE GOODS, TECHNOLOGY, RIGHTS OR SERVICES, OR SPECIAL,
//           INDIRECT, INCIDENTAL, PUNITIVE, COMPENSATORY OR CONSEQUENTIAL DAMAGES
//           OF ANY KIND WHATSOEVER.
//         </h3>

//         <p>Enjoy the game responsibly!</p>

//         <div class="footer">
//           <div class="footerContainer">
//             <div class="footerLogo">
//               <img src="./kingdom_logo.png" alt="" />
//             </div>

//             <div class="rightContainer">
//               <div class="buttonContainer">
//                 <a href="https://t.me/Kingdom_erc20">
//                   <img src="./telegramB.png" alt="" />
//                 </a>
//                 <a href="https://twitter.com/KINGDOMGAME_ERC">
//                   <img src="./twitterB.png" alt="" />
//                 </a>
//                 <a href="https://dune.com/whale_hunter/kingdom-game">
//                   <img src="./analyticsB.png" alt="" />
//                 </a>
//                 <a href="https://dexscreener.com/ethereum/0x7ac46647b0b6850f445aaded0b2694c927cb0539">
//                   <img src="./dexB.png" alt="" />
//                 </a>
//                 <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x7ac46647b0b6850f445aaded0b2694c927cb0539">
//                   <img src="./toolB.png" alt="" />
//                 </a>
//               </div>

//               <div class="linkContainer">
//                 <a href="https://docs.kingdomgame.live/">Documentation</a>
//                 <a href="/legal">Legal</a>
//               </div>
//             </div>
//           </div>
//           <div class="disclaimer">
//             Kingdom game is not available to people living in the United States
//             or any other country that is subject to an embargo imposed by the
//             U.S.
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }


import React from "react";
import styles from "./Legal.module.css";

import { Link } from "react-router-dom";

export default function Legal() {
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.headerContainer}>
          <div className={styles.header}>
            <a href="/">
              <img
                className={styles.headerLogo}
                src="./kingdom_logo.png"
                alt=""
              />
            </a>
            <a href="https://docs.kingdomgame.live/how-to-play">
              <img className={styles.headerButton} src="./button.png" alt="" />
            </a>
          </div>
          <div></div>
        </div>
        <h1 className={styles.title}>LEGAL</h1>
        <h2 className={styles.subHeading}>LEGAL DISCLAIMER</h2>
        <p>
          <strong>Kingdom Game</strong> (“Game”) is intended for individuals who
          are at least 18 years old, unless otherwise stated by local law. By
          using the Game, you represent and warrant that you meet the age
          requirements. If you are under the specified age, please refrain from
          playing the Game.
        </p>

        <p>
          Game was developed and designed primarily for entertainment and
          amusement only. The Game is played using tokens.. The rewards are
          limited to the high scores (/leaderboard) during the day and the
          tokens collected within the game. To win the Game you require skill,
          hand-eye coordination, and strategy to predict the outcome of the
          Game. You can improve your performance through practice and
          experience.
        </p>

        <p>
          <strong>Kingdom Game</strong> will be available only in Telegram and
          only for token holders. We reserve the right, in our sole discretion,
          to remove, edit, or modify any features without notice to you.
        </p>

        <p>
          Project native token <strong>$KINGDOM </strong>(“Token”) is available
          for purchase on Uniswap. Under no circumstances does the
          <strong> Kingdom Game</strong> team provide you with recommendations
          to buy the Token, sell it, or hold it.
        </p>

        <p>
          When purchasing the Token, you agree to the conditions that you are
          not purchasing a security or investment, as well as you agreeing to
          relieve the Team of any damage and responsibility for any losses or
          tax liabilities you may incur. You also agree with the fact that when
          offering the Token, the Team has no obligation to provide support or
          services regarding the Token.
        </p>

        <p>
          You should not have any expectations of the Token and the Team. Make
          sure you comply with your local laws and regulations before making any
          purchase of the Token. We do not bear any responsibility for any
          losses or damages of any kind incurred as a result of using this
          information or services. Please be aware of the risks related to
          trading on a financial market connected with the feasible loss of
          money. In this regard, we kindly recommend you not to trade with money
          you are not ready to lose. In any case, you take the full
          responsibility for your investments and trading decisions.
        </p>

        <h2>Prohibition of Bot Usage :-</h2>
        <ul>
          <li>
            You are strictly prohibited from using any kind of automated trading
            systems, bots including but not limited to sniper bots or other
            software or technology that can manipulate the price, exploit
            vulnerabilities, or unfairly benefit from the Token.
          </li>
          <br />
          <li>
            You shall not engage in any activity that disrupts or compromises
            the integrity of the Token, the project ecosystem, or the platform
            on which the Token is hosted.
          </li>
        </ul>

        <p>
          The project reserves the right to investigate any suspicious or
          fraudulent activities related to the Token and take appropriate action
          based on its findings.
        </p>

        <h2>Price Manipulation :-</h2>
        <ul>
          <li>
            You shall not engage in any activity that artificially manipulates
            the price or market conditions of the Token.
          </li>
          <br />

          <li>
            You shall not engage in pump-and-dump schemes, wash trading,
            front-running, sniping, or any other manipulative practices that can
            create a false or misleading market for the project's token.
          </li>
        </ul>

        <p>
          The Game is provided "as is" and "as available," without warranties of
          any kind, whether express or implied. We do not warrant that the Game
          will be error-free, uninterrupted, or free from viruses or other
          harmful components.
        </p>

        <p>
          To the fullest extent permitted by applicable law, the Kingdom Game
          project shall not be liable for any indirect, incidental, special,
          consequential, or punitive damages, or any loss of profits or
          revenues, whether incurred directly or indirectly, or any loss of
          data, use, goodwill, or other intangible losses resulting from your
          use or inability to use the Game.
        </p>

        <p>
          You agree to indemnify, defend, and hold harmless the Project, its
          officers, directors, employees, and agents from and against any
          claims, liabilities, damages, losses, and expenses arising out of your
          use of the Game.
        </p>

        <h3>
          IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, AGENTS, MANAGERS,
          MEMBERS, PRINCIPALS, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS
          (“RELATED PARTIES”), BE LIABLE UNDER CONTRACT, STRICT LIABILITY,
          NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE
          PROJECT FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF
          SUBSTITUTE GOODS, TECHNOLOGY, RIGHTS OR SERVICES, OR SPECIAL,
          INDIRECT, INCIDENTAL, PUNITIVE, COMPENSATORY OR CONSEQUENTIAL DAMAGES
          OF ANY KIND WHATSOEVER.
        </h3>

        <p>Enjoy the game responsibly!</p>

        <div className={styles.footer}>
          <div className={styles.footerContainer}>
            {/* <div className={styles.footerLogo}>
              <img src="./kingdom_logo.png" alt="" />
            </div> */}

            {/* <div className={styles.rightContainer}>
              <div className={styles.buttonContainer}>
                <a href="https://t.me/Kingdom_erc20">
                  <img src="./telegramB.png" alt="" />
                </a>
                <a href="https://twitter.com/KINGDOMGAME_ERC">
                  <img src="./twitterB.png" alt="" />
                </a>
                <a href="https://dune.com/whale_hunter/kingdom-game">
                  <img src="./analyticsB.png" alt="" />
                </a>
                <a href="https://dexscreener.com/ethereum/0x7ac46647b0b6850f445aaded0b2694c927cb0539">
                  <img src="./dexB.png" alt="" />
                </a>
                <Link to="https://www.dextools.io/app/en/ether/pair-explorer/0x7ac46647b0b6850f445aaded0b2694c927cb0539">
                  <img src="./toolB.png" alt="" />
                </Link>
              </div>

              <div className={styles.linkContainer}>
                <a href="https://docs.kingdomgame.live/">Documentation</a>
                <a href="/legal">Legal</a>
              </div>
            </div> */}
            <div className={styles.rightContainer}>
              <div className={styles.buttonContainer}>
                <a href="https://player.castr.com/live_c84f6aa06aa511eebe76bbb652628f31/realtime">
                  <img src="./castr.png" alt="" />
                </a>
                <a href="https://t.me/Kingdom_erc20">
                  <img src="./telegram.png" alt="" />
                </a>
                <a href="https://twitter.com/i/broadcasts/1DXxyjwZdaPKM">
                  <img src="./ButtonX.png" alt="" />
                </a>
                <a href="https://dune.com/whale_hunter/kingdom-game">
                  <img src="./analytics.png" alt="" />
                </a>
                <a href="https://dexscreener.com/ethereum/0x7ac46647b0b6850f445aaded0b2694c927cb0539">
                  <img src="./dexscreener.png" alt="" />
                </a>
                <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x7ac46647b0b6850f445aaded0b2694c927cb0539">
                  <img src="./dextools.png" alt="" />
                </a>
              </div>

              <div className={styles.linkContainer}>
                <div className={styles.footerLogo}>
                  <img src="./kingdom_logo.png" alt="" />
                </div>
                <div className={styles.subLinkCaontainer}>
                  <a href="https://docs.kingdomgame.live/">Documentation</a>
                  <a href="/legal">Legal</a>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.disclaimer}>
            Kingdom game is not available to people living in the United States
            or any other country that is subject to an embargo imposed by the
            U.S.
          </div>
        </div>
      </div>
    </div>
  );
}
