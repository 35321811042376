import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./Home";
import Legal from "./Legal";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/legal" element={<Legal />} />
        {/*
        <Route path="/contact" element={<Contact />} /> */}
      </Routes>
    </Router>
  );
}





export default App;
